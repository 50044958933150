import MKBox from 'components/MKBox';
import React from 'react';


import map1 from "assets/hada2ek-images/contact/map-1.jpg";
import map2 from "assets/hada2ek-images/contact/map-2.jpg";
import bgMain from "assets/hada2ek-images/bg/bg-main.png";
import { Grid, Typography, Container, Icon } from '@mui/material';
import PropTypes from "prop-types";


function ContactSection2({ id }) {
    return (
        <MKBox
            id={id}
            className="scroll-item"
            minHeight="100vh"
            width="100%"
            sx={{
                backgroundImage: `url(${bgMain})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative"
            }}
        >
            <Container sx={{ paddingTop: "100px", height: "100vh" }}>
                <Grid container alignItems="space-between" height="100%">
                    <Grid item container alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item md={4} textAlign="center">
                            <Typography variant="h5">
                                <Icon sx={{ marginRight: "20px" }}>phone</Icon>
                                <br />
                                (+202) 2574 23 91
                            </Typography>
                        </Grid>
                        <Grid item md={4} textAlign="center">
                            <Typography variant="h5">
                                <Icon sx={{ marginRight: "20px" }}>phone</Icon>
                                <br />
                                (+202) 2574 23 85
                            </Typography>
                        </Grid>
                        <Grid item md={4} textAlign="center">
                            <Typography variant="h5">
                                <Icon sx={{ marginRight: "20px" }}>mail</Icon>
                                <br />
                                info@hadayiek.com
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="flex-end" spacing={2}>
                        <Grid item md={6} sx={{ paddingRight: "20px" }}>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: "800",
                                    paddingBottom: "8px",
                                }}
                            >
                                Head Office: 1119 Nile Corniche, Cairo, Egypt
                            </Typography>
                            <a href="https://maps.app.goo.gl/sofrfdWeLFkRFEyR7">
                                <MKBox
                                    component="img"
                                    alt="The house from the offer."
                                    src={map1}
                                    className="img-responsive"
                                />
                            </a>
                        </Grid>
                        <Grid item md={6} sx={{ paddingLeft: "20px" }}>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: "800",
                                    paddingBottom: "8px"
                                }}
                            >
                                Technical Office: Giza Zoo, Gate 1, Mourad St., Giza , Egypt
                            </Typography>

                            <a href="https://maps.app.goo.gl/rEcEqJjKsN3tbXfC7">
                                <MKBox
                                    component="img"
                                    alt="The house from the offer."
                                    src={map2}
                                    className="img-responsive"
                                />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}
ContactSection2.propTypes = {
    id: PropTypes.string.isRequired,
}
export default ContactSection2;