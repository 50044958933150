
const routes = [
  {
    name: "Home",
    href: "home",
    icon: <></>,
  },
  {
    name: "About",
    href: "about",
    icon: <></>,
  },
  {
    name: "Projects",
    href: "projects",
    icon: <></>,
  },
  {
    name: "Gallery",
    href: "gallery",
    icon: <></>,
  },
  {
    name: "Contact Us",
    href: "contact",
    icon: <></>,
  },
  // {
  //   name: "github",
  //   icon: <GitHubIcon />,
  //   href: "https://www.github.com/creativetimofficial/material-kit-react",
  // },
  // {
  //   name: "github",
  //   icon: <Icon>html</Icon>,
  //   href: "https://www.github.com/creativetimofficial/material-kit-react",
  // },
];

export default routes;
