/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Routes
import routes from "routes";


//import bgLogo from "assets/hada2ek-images/logo.png";
// import bg1 from "assets/hada2ek-images/bg/bg-1.jpg";
// import bg2 from "assets/hada2ek-images/bg/bg-2.jpg";
// import bgAnimal1 from "assets/hada2ek-images/animals/animal-1.jpg";
// import bgAnimal2 from "assets/hada2ek-images/animals/animal-2.jpg";
// import bgAnimal3 from "assets/hada2ek-images/animals/animal-3.jpg";
// import bgAnimal4 from "assets/hada2ek-images/animals/animal-4.jpg";
// import bgAnimal5 from "assets/hada2ek-images/animals/animal-5.jpg";
// import bgAnimal6 from "assets/hada2ek-images/animals/animal-6.jpg";
// const animalImages = [
//   bgAnimal1, bgAnimal2, bgAnimal3, bgAnimal4, bgAnimal5, bgAnimal6
// ]

import bgLogo from "assets/hada2ek-images/logo_white.png";

import MyNavbar from "examples/Navbars/MyNavbar";

import "../../assets/hada2ek-styles/snapping.css";    // removing this disrupted the sticky navbar (i dont know why)
import createScrollSnap from "scroll-snap";
import { useLayoutEffect } from "react";

import 'assets/hada2ek-styles/mystyles.css';


// import required modules
import MapSection2 from "./Sections2/MapSection2";
import ContactSection2 from "./Sections2/ContactSection2";
import GallerySection2 from "./Sections2/GallerySection2";
import MissionSection2 from "./Sections2/MissionSection2";
import AboutSection2 from "./Sections2/AboutSection2";
import StatsSection2 from "./Sections2/StatsSection2";
import HomeSection2 from "./Sections2/HomeSection2";
import TeamSection2Split from "./Sections2/TeamSection2Split";



const enableSnapping = false

function Hada2ek() {

  if (enableSnapping) {
    useLayoutEffect(() => {
      const element = document.getElementById('scroll-container');

      createScrollSnap(element, {
        snapDestinationX: '0%',
        snapDestinationY: '90%',
        timeout: 100,
        duration: 300,
        threshold: 0.2,
        snapStop: false,
        easing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
      }, () => console.log('element snapped'));
    }, [])
  }

  return (
    <>
      <MyNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        sticky
        light
        transparent
        brandText=""
        brandImage={bgLogo}
      />

      <div id="scroll-container" style={{ height: "100vh" }}>
        {/* 
        <HomeSection id="home" background={bg1} />
        <HomeSection id="home2" background={bg2} />
        <TeamSection id="team" />
        <AboutSection id="about" />
        <MissionSection id="mission" />
        <StatsSection id="stats" />
        <MapSection id="projects" />

        {
          animalImages.map(function (animalImg, i) {
            return (<AnimalSection key={i} animalImg={animalImg} />)
          })
        }
        <GallerySection id="gallery" />
        <ContactSection id="contact" />

         */}

        <HomeSection2 id="home" />
        {/* <TeamSection2 id="team" /> */}
        <TeamSection2Split id="team" />
        <MissionSection2 id="mission" />
        <AboutSection2 id="about" />
        <StatsSection2 id="stats" />
        <MapSection2 id="projects" />
        <GallerySection2 id="gallery" />
        <ContactSection2 id="contact" />

      </div >
    </>
  );
}

export default Hada2ek;
