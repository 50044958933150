import { Box, Typography } from '@mui/material';
import React from 'react';
import stat_icon from "assets/hada2ek-images/stat_icon.png";
import PropTypes from 'prop-types';

function StatIcon(props) {
    return (
        <Box className="statsbox">
            <Box sx={{
                position: "relative",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <img
                    alt="stat icon"
                    src={stat_icon}
                    style={{ width: "150px", height: "150px", }}
                />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                }}>
                    <Typography
                        variant="h3"
                        fontWeight={800}
                        color="#9ec252"
                    >
                        {props.stat}
                    </Typography>
                </Box>
            </Box>
            <Typography
                variant="body2"
                fontWeight={800}
                textTransform="uppercase"
                sx={{
                    textAlign: "center",
                    height: "100px",
                    color: "#231f20",
                    textShadow: "3px 2px 5px white"
                }}
            >
                {props.subtitle}
            </Typography>
        </Box >
    );
}

StatIcon.propTypes = {
    stat: PropTypes.string,
    subtitle: PropTypes.string,
};
export default StatIcon;