import MKBox from 'components/MKBox';
import React from 'react';



import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from "prop-types";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';


import bgAnimal1 from "assets/hada2ek-images/gallery/G1.jpg";
import bgAnimal2 from "assets/hada2ek-images/gallery/G2.jpg";
import bgAnimal3 from "assets/hada2ek-images/gallery/G3.jpg";
import bgAnimal4 from "assets/hada2ek-images/gallery/G4.jpg";
import bgAnimal5 from "assets/hada2ek-images/gallery/G5.jpg";
import bgAnimal6 from "assets/hada2ek-images/gallery/G6.jpg";
import bgAnimal7 from "assets/hada2ek-images/gallery/G7.jpg";
import { Grid, Typography } from '@mui/material';
const animalImages = [
    bgAnimal1, bgAnimal2, bgAnimal3, bgAnimal4, bgAnimal5, bgAnimal6, bgAnimal7
]

function GallerySection2({ id }) {


    return (
        <MKBox
            id={id}
            className="scroll-item"
            minHeight="100vh"
            width="100%"
            position="relative"
        >

            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation, Pagination]}
            >
                {
                    animalImages.map(function (animalImg, i) {
                        return (
                            <SwiperSlide key={i}>
                                <MKBox
                                    alt="Gallery Image"
                                    sx={{
                                        width: "auto",
                                        height: "100vh",
                                        backgroundImage: `url(${animalImg})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        position: "relative"
                                    }}
                                />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>


            <Grid container
                alignItems="flex-end"
                justifyContent="space-between"
                p={4}
                sx={{ position: "absolute", bottom: "0px", zIndex: "2" }}
            >
                <Grid item sm={3}>
                    <Typography variant="h1" textTransform="uppercase" lineHeight={0.4}>Giza</Typography>
                    <Typography variant="h1" textTransform="uppercase">Zoo</Typography>
                </Grid>
                <Grid item sm={6} textAlign="end" >
                    <Typography variant="body1">21ST CENTURY PLEASURE GARDEN POWERED BY</Typography>
                    <Typography variant="h1" textTransform="uppercase">Hadayieq</Typography>

                </Grid>
            </Grid>
        </MKBox >
    );
}
GallerySection2.propTypes = {
    id: PropTypes.string.isRequired,
}
export default GallerySection2;