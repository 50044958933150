import MKBox from 'components/MKBox';


import PropTypes from "prop-types";

import bgMain from "assets/hada2ek-images/bg/bg-main.png";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';



import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';

import bgAnimal2 from "assets/hada2ek-images/animals/2 copy.png";
import bgAnimal3 from "assets/hada2ek-images/animals/3 copy.png";
import bgAnimal4 from "assets/hada2ek-images/animals/4 copy.png";
import bgAnimal5 from "assets/hada2ek-images/animals/5 copy.png";

import h_bgAnimal2 from "assets/hada2ek-images/animals/H 2 copy.png";
import h_bgAnimal3 from "assets/hada2ek-images/animals/H 3 copy.png";
import h_bgAnimal4 from "assets/hada2ek-images/animals/H 4 copy.png";
import h_bgAnimal5 from "assets/hada2ek-images/animals/H 5 copy.png";
import { useTheme } from '@emotion/react';



const animalImages = [
    [bgAnimal2, h_bgAnimal2],
    [bgAnimal3, h_bgAnimal3],
    [bgAnimal4, h_bgAnimal4],
    [bgAnimal5, h_bgAnimal5],
]



function HomeSection2({ id }) {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <MKBox
            id={id}
            className="scroll-item"
            minHeight="100vh"
            width="100%"
            sx={{
                backgroundImage: `url(${bgMain})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative"
            }}
        >
            <Container sx={{
                paddingTop: "100px", height: "100vh"
            }}>
                <Box sx={{ display: "flex", height: "100%", justifyContent: "center", position: "relative" }}>

                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        navigation={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Navigation, Pagination]}
                    >
                        {
                            animalImages.map(function (animalImg, i) {
                                return (
                                    <SwiperSlide key={i}>
                                        <MKBox
                                            component="img"
                                            alt="Gallery Image"
                                            src={isSmallScreen ? animalImg[1] : animalImg[0]}
                                            sx={{ width: "100%", height: "100%" }}
                                        />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>


                    <Grid container
                        id="slidertop"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        p={4}
                        sx={{ position: "absolute", top: "0px", left: "0px", right: "0px", zIndex: "5", }}
                    >
                        <Grid item sm={4}>
                            <Typography variant="h3" textTransform="uppercase">
                                ZOOS &
                                <br />
                                RECREATIONAL FACILITIES MANAGMENT
                            </Typography>
                        </Grid>

                        <Grid item sm={4} paddingTop={2}>
                        </Grid>
                    </Grid>

                </Box>
            </Container>


            <Grid container
                alignItems="flex-end"
                justifyContent="space-between"
                p={4}
                sx={{ position: "absolute", bottom: "0px", zIndex: "2", }}
            >
                <Grid item sm={4}>
                </Grid>

                <Grid item sm={6} paddingTop={2} textAlign="end">
                    <Typography variant="body1">21ST CENTURY PLEASURE GARDEN POWERED BY</Typography>
                    <Typography variant="h1" textTransform="uppercase" color="hadayieq.lightgreen">Hadayieq</Typography>
                </Grid>
            </Grid>

        </MKBox >
    );
}

HomeSection2.propTypes = {
    id: PropTypes.string.isRequired,

}
export default HomeSection2;