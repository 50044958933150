import MKBox from 'components/MKBox';
import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import bgMain from "assets/hada2ek-images/bg/bg-main.png";
import logoTrans from "assets/hada2ek-images/logo_trans.png";
import PropTypes from "prop-types";


function AboutSection2({ id }) {
    return (
        <MKBox
            id={id}
            className="scroll-item"
            minHeight="100vh"
            width="100%"
            sx={{
                backgroundImage: `url(${bgMain})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative"
            }}
        >
            <Container sx={{ paddingTop: "100px", height: "100vh" }}>
                <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                    <Grid item md={5}>
                        <MKBox
                            component="img"
                            alt="The house from the offer."
                            src={logoTrans}
                            sx={{ width: "100%" }}
                            marginBottom="20px"
                            px={6}
                        />
                        <Typography variant="h1" color="dark.focus" sx={{ textAlign: "center", display: { md: "inherit", xs: "none" } }}>HADAYIEQ</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2"  >

                            <Typography component="span" lineHeight={0.1} sx={{ color: "dark.focus" }} > HADAYIEQ</Typography>
                            <Typography component="span" lineHeight={0.1} sx={{}}>
                                is a private sector zoological and recreational
                                facility management company that thrives on developing zoos
                                as well as providing guests with an exquisite entertainment
                                experience.
                            </Typography>
                            <br />
                            <Typography component="span" lineHeight={0.1} sx={{ color: "dark.focus" }} > HADAYIEQ</Typography>
                            <Typography component="span" lineHeight={0.1} sx={{}}>
                                has a top-notch team of partners, consultants, and
                                industry leaders who are committed to delivering the best
                                experiences for both animals and visitors in accordance with
                                the international standards.
                            </Typography>
                            <br />
                            <Box sx={{ display: { md: "inherit", xs: "none" } }}>
                                <Typography component="span" lineHeight={0.1} sx={{ color: "dark.focus", }} > HADAYIEQ</Typography>
                                <Typography component="span" lineHeight={0.1} sx={{}}>
                                    is the concession owner and developer of Giza Zoo.
                                </Typography>
                                <br />
                                <Typography component="span" lineHeight={0.1} sx={{ color: "dark.focus", }} > HADAYIEQ</Typography>
                                <Typography component="span" lineHeight={0.1} sx={{}}>
                                    is committed to the animal welfare, preserving
                                    heritage. Creating a world-class entertaining experience.
                                </Typography>
                            </Box>
                        </Typography>
                    </Grid >
                </Grid >
            </Container >
        </MKBox >
    );
}
AboutSection2.propTypes = {
    id: PropTypes.string.isRequired,
};

export default AboutSection2;