import MKBox from 'components/MKBox';
import React from 'react';
import PropTypes from "prop-types";
import bgStats from "assets/hada2ek-images/bg/bg-stats2.jpg";
import StatIcon from "../Components/StatIcon";
import { Container, Grid } from '@mui/material';

function StatsSection2({ id }) {
    return (
        <MKBox
            id={id}
            className="scroll-item"
            minHeight="100vh"
            width="100%"
            sx={{
                backgroundImage: `url(${bgStats})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative"
            }
            }
        >
            <Container sx={{ paddingTop: "100px", height: "100vh" }}>
                <Grid container justifyContent="center" alignItems="flex-end" sx={{ height: "100%" }}>
                    <Grid item lg={3} sm={3} xs={6}>
                        <StatIcon subtitle="Historical Monuments" stat="8+"></StatIcon>
                    </Grid>
                    <Grid item lg={3} sm={3} xs={6}>
                        <StatIcon subtitle="Trees" stat="5K+"></StatIcon>
                    </Grid>
                    <Grid item lg={3} sm={3} xs={6}>
                        <StatIcon subtitle="Species" stat="178+"></StatIcon>
                    </Grid>
                    <Grid item lg={3} sm={3} xs={6}>
                        <StatIcon subtitle="Visitors Anually" stat="5M+"></StatIcon>
                    </Grid>
                </Grid>
            </Container>
        </MKBox >
    );
}
StatsSection2.propTypes = {
    id: PropTypes.string.isRequired,
}
export default StatsSection2;