import React from 'react';
import PropTypes from "prop-types";

import { Box, Container, Grid, Typography } from '@mui/material';

import bgAbout from "assets/hada2ek-images/bg/bg-mission.jpg";
import MKBox from 'components/MKBox';


function MissionSection2({ id }) {
    return (
        <MKBox
            id={id}
            className="scroll-item"
            minHeight="100vh"
            width="100%"
            sx={{
                backgroundImage: `url(${bgAbout})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative"
            }}
        >
            <Container sx={{ paddingTop: "100px", height: "100vh" }}>
                <Grid container justifyContent="space-between" alignItems="center" sx={{ height: "100%" }}>
                    <Grid item sm={5}>
                        <Typography variant="h1" fontWeight={700} textTransform="uppercase" color="hadayieq.lightwhite" lineHeight={0.7}>Mission</Typography>
                        <Typography variant="body2" backgroundColor="#e0e0e1cc" color="hadayieq.darkgreen" fontWeight={600} >
                            To excel in the effective management of zoos, our company is committed to delivering
                            exceptional operational expertise, animal welfare standards, and visitor experiences.
                            We prioritize sustainability, conservation, and ethical practices, aiming to establish
                            zoos as centers for education, research, and the preservation of biodiversity.
                        </Typography>


                    </Grid>
                    <Grid item sm={5}>
                        <Typography variant="h1" fontWeight={700} textTransform="uppercase" color="hadayieq.lightwhite" lineHeight={0.7}>Vision</Typography>
                        <Typography variant="body2" backgroundColor="#e0e0e1cc" color="hadayieq.darkgreen" fontWeight={600} >
                            As a leading management company for zoos, we envision a future where every zoo under
                            our guidance is a beacon of conservation excellence. Our vision includes zoos seamlessly
                            integrating into global safeguarding efforts, contributing significantly to species
                            preservation, and fostering a profound connection between people and the natural world.

                            <Box component="span" sx={{ display: { md: "inherit", xs: "none" } }}>
                                Through our leadership, we strive to set the standard for responsible and impactful zoo management worldwide.
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

        </MKBox >
    );
}
MissionSection2.propTypes = {
    id: PropTypes.string.isRequired,
}
export default MissionSection2;