
import MKBox from 'components/MKBox';
import React from 'react';
import PropTypes from "prop-types";

import animals from "assets/hada2ek-images/map/animals-title-text.png";
import map1 from "assets/hada2ek-images/map/map-v.png";
import map2 from "assets/hada2ek-images/map/map-h.png";
import logo_des from "assets/hada2ek-images/map/logo_design.png";
import logo_oper from "assets/hada2ek-images/map/logo_oper.png";

import { Grid, Container, Table, TableBody, TableRow, TableCell, styled, Typography, Box } from '@mui/material';

import bgMain from "assets/hada2ek-images/bg/bg-main.png";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    'td, th': {
        border: 0,
        color: theme.palette.common.white,
        padding: "0px 6px"
    },
}));

const tableFiguresStyle = {
    color: "hadayieq.darkgreen",
    fontWeight: "900",
    fontSize: {
        lg: "20px",
        xs: "12px"
    }
}
const tableLabelsStyle = {
    color: "hadayieq.darkgreen",
    fontWeight: "900",
    fontSize: "12px"
}
function MapSection2({ id }) {
    return (

        <MKBox
            id={id}
            className="scroll-item"
            minHeight="100vh"
            width="100%"
            sx={{
                backgroundImage: `url(${bgMain})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
            }}
        >

            <Container sx={{ paddingTop: "100px" }}>
                <Grid container spacing={2} sx={{ height: "80vh" }} >
                    <Grid item container lg={2} md={3} direction="column" alignItems="center" justifyContent="space-evenly" >
                        <MKBox
                            component="img"
                            alt="The house from the offer."
                            src={animals}
                            sx={{
                                width: "100%",
                                maxWidth: "220px",
                                display: {
                                    lg: "inherit",
                                    xs: "none"
                                }
                            }}

                        />
                        <Table
                            sx={{
                                marginTop: "30px",
                                backgroundColor: "hadayieq.lightgreen",

                            }}
                        >
                            <TableBody>
                                <StyledTableRow>
                                    <TableCell>
                                        <Typography sx={tableLabelsStyle} textTransform="uppercase" >Attractions</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={tableFiguresStyle}>12</Typography>
                                    </TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell>
                                        <Typography sx={tableLabelsStyle} textTransform="uppercase">Species</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={tableFiguresStyle}>178</Typography>
                                    </TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell>
                                        <Typography sx={tableLabelsStyle} textTransform="uppercase">Parade</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={tableFiguresStyle}>6</Typography>
                                    </TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell>
                                        <Typography sx={tableLabelsStyle} textTransform="uppercase">Complex</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={tableFiguresStyle}>20</Typography>
                                    </TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell>
                                        <Typography sx={tableLabelsStyle} textTransform="uppercase">Rides</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={tableFiguresStyle}>4</Typography>
                                    </TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell>
                                        <Typography sx={tableLabelsStyle} textTransform="uppercase">Educational</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={tableFiguresStyle}>3</Typography>
                                    </TableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item container lg={10} md={9} direction="column" justifyContent="space-between">

                        <Grid item container
                            sx={{
                                paddingTop: {
                                    lg: "50px",
                                    xs: "0px"
                                }
                            }} >
                            <Grid item xs={3}>

                                <MKBox
                                    component="img"
                                    alt="map 1."
                                    src={map1}
                                    className="img-responsive"
                                />
                            </Grid>
                            <Grid item xs={9}>

                                <MKBox
                                    component="img"
                                    alt="map 2."
                                    src={map2}
                                    className="img-responsive"
                                />
                            </Grid>
                        </Grid>


                        <Grid item container >
                            <Grid item lg={8}>
                                <Typography
                                    sx={{
                                        typography: {
                                            lg: "body1",
                                            xs: "body2"
                                        },
                                        display: {
                                            lg: "inherit",
                                            xs: "none"
                                        }
                                    }}>
                                    THE TWO GARDENS WILL BE CONNECTED TO BECOME ONE OF THE LARGEST ZOOLOGICAL AND BOTANICAL GARDENS WITHIN THE CITY WORLDWIDE
                                </Typography>
                            </Grid>
                            <Grid item container lg={4} alignItems="center" justifyContent="center">
                                <Box sx={{ textAlign: "center" }}>
                                    <Typography textTransform="uppercase" fontSize={8} color="hadayieq.lightgreen" fontWeight={800}>operations consultant</Typography>
                                    <MKBox
                                        component="img"
                                        alt="The house from the offer."
                                        src={logo_oper}
                                        className="img-responsive"
                                        px={1}
                                    />
                                </Box>
                                <Box sx={{ textAlign: "center" }}>
                                    <Typography
                                        textTransform="uppercase"
                                        fontSize={8}
                                        color="hadayieq.lightgreen"
                                        fontWeight={800}
                                    >
                                        design consultant
                                    </Typography>
                                    <MKBox
                                        component="img"
                                        alt="The house from the offer."
                                        src={logo_des}
                                        className="img-responsive"
                                        px={1}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox >
    );
}

MapSection2.propTypes = {
    id: PropTypes.string.isRequired,
}
export default MapSection2;