import React from 'react';
import PropTypes from "prop-types";
import MKBox from 'components/MKBox';
import { Box, Container, Typography } from '@mui/material';

import bgMain from "assets/hada2ek-images/bg/bg-main.png";

import person1 from "assets/hada2ek-images/team/person1.png";
import person2 from "assets/hada2ek-images/team/person2.png";
import person3 from "assets/hada2ek-images/team/person3.png";
import person4 from "assets/hada2ek-images/team/person4.png";
import person5 from "assets/hada2ek-images/team/person5.png";
import person6 from "assets/hada2ek-images/team/person6.png";
import person7 from "assets/hada2ek-images/team/person7.png";

const personImages = [
    [person1, "Mohamed <br/>Badr"],
    [person2, "Jassmine <br/>Taha"],
    [person3, "Dr. Tarek <br/>Shawky"],
    [person4, "Mohamed <br/>Kamel"],
    [person5, "Dr. Enas <br/>Abd ElDayem"],
    [person6, "Kamal <br/>El Daly"],
    [person7, "Mohamed <br/>Marei"],
]

function TeamSection2Split() {
    return (
        <MKBox
            className="scroll-item"
            minHeight="100vh"
            width="100%"
            sx={{
                backgroundImage: `url(${bgMain})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative"
            }}
        >

            <Container sx={{ paddingTop: "100px", height: "100vh" }}>

                <Typography
                    textAlign="center"
                    width="100%"
                    sx={{ fontSize: { lg: "60pt", sm: "36pt", xs: "22pt" } }}
                    textTransform="uppercase"
                    fontWeight={800}
                    position="absolute"
                    top="100px"
                    left="0"
                    right="0"
                >
                    Board Members
                </Typography>

                <Box sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "100px"
                }}>

                    {
                        personImages.map(function (person, i) {
                            return (
                                <Box key={i}
                                    sx={{
                                        width: { lg: "14%", sm: "14%", xs: "25%" },
                                        zIndex: "5"
                                    }}
                                    className="person-box-hover"
                                >
                                    <MKBox
                                        component="img"
                                        alt="Gallery Image"
                                        src={person[0]}
                                        className="img-responsive person-hover"
                                    />
                                    <Typography
                                        variant="body2"
                                        textAlign="center"
                                        color="hadayieq.lightgreen"
                                        fontWeight={800}
                                        textTransform="uppercase"
                                        sx={{ fontSize: { lg: "inherit", xs: "12px" } }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: person[1] }} />
                                    </Typography>
                                </Box>
                            )
                        })
                    }
                </Box>


            </Container>
        </MKBox >
    );
}


TeamSection2Split.propTypes = {
    id: PropTypes.string.isRequired,
}
export default TeamSection2Split;